<template>
	<div>
		<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  		{{textDF}}
	</div>
</template>
<script type="text/javascript">
	export default {
		props: {
			text: String
		},
		computed:{
			textDF(){
				return this.text || 'Cargando...'
			}
		}
	}
</script>